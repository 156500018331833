<template>
  <div class="user">
    <FormView :formData="fromData" :formLabels="formLabels" :column="formColumn" :labelWidth="labelWidth">
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView :loading="loading" :columns="columns" :data="rTableData" :pagination="pagination"
      :is-pagination-show="isPaginationShow" @getData="getDataList">
      <template #action>
        <el-table-column label="操作" align="center">
          <template #default="scoped">
            <el-button type="primary" size="small">
              售后详情
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage } from 'element-plus'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '售后单号',
      placeholder: '请输入'
    },
    {
      label: 'keywords',
      title: '订单编号',
      placeholder: '请输入'
    },
    {
      label: 'keywords',
      title: '用户昵称',
      placeholder: '请输入'
    },
    {
      title: '退款方式',
      label: 'status',
      type: 'options',
      options: [
        { value: 1, label: '封号' },
        { value: 0, label: '正常' }
      ],
      placeholder: '请选择'
    },
    {
      title: '售后类型',
      label: 'type',
      type: 'options',
      options: [
        { value: 1, label: '工作人员' },
        { value: 0, label: '顾客' }
      ],
      placeholder: '请选择'
    },
    {
      title: '售后状态',
      label: 'type',
      type: 'options',
      options: [
        { value: 1, label: '工作人员' },
        { value: 0, label: '顾客' }
      ],
      placeholder: '请选择'
    },
    {
      title: '订单状态',
      label: 'type',
      type: 'options',
      options: [
        { value: 1, label: '工作人员' },
        { value: 0, label: '顾客' }
      ],
      placeholder: '请选择'
    },
    {
      title: '申请时间',
      label: 'time',
      type: 'datePicker',
      width: '500px'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: '',
    member: '',
    type: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { prop: 'nickName', label: '售后订单' },
    { prop: 'phone', label: '买家昵称' },
    { prop: 'typeName', label: '商品信息' },
    { prop: 'currentActiveIntegral', label: '数量' },
    { prop: 'totalActiveIntegral', label: '退款金额' },
    { prop: 'currentShoppingIntegral', label: '退款方式' },
    { prop: 'totalShoppingIntegral', label: '售后类型' },
    { prop: 'lastLoginTime', label: '售后状态' },
    { prop: 'stateName', label: '申请时间' },
    { slot: 'action' }
  ]
})

onMounted(() => {
  // getDataList()
})


// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: '',
    type: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.word = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.banState = fromData.value.status
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  guestPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      res.data.records.forEach(item => {
        item.stateName = item.banState == 1 ? '封号' : '正常'
        item.newUserName = item.newUser ? '是' : '否'
      })
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}
const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
} = toRefs(initData)
</script>
<style lang="scss" scoped></style>
